import React from "react";

function QueueIcon() {
  return (
    <svg
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M9.594.596a.91.91 0 01.813 0l9.09 4.545a.909.909 0 010 1.627l-9.09 4.545a.91.91 0 01-.813 0L.503 6.768a.91.91 0 010-1.627L9.593.596zM10 2.426L2.942 5.954 10 9.484l7.058-3.53L10 2.426zM1.316 14.231L10 18.575l8.685-4.343a.91.91 0 01.813 1.627l-9.091 4.545a.91.91 0 01-.813 0L.503 15.859a.91.91 0 01.813-1.627v-.001zm0-4.545L10 14.029l8.685-4.342a.91.91 0 01.813 1.626l-9.091 4.546a.91.91 0 01-.813 0L.503 11.313a.91.91 0 11.813-1.626v-.001z"
          fill="#3C4257"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h20v21H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default QueueIcon;
