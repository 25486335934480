import CompanyAvgDataResponseType from "types/Analytics/api/CompanyAvgDataResponseType";

type demoDataType = {
  prevData: CompanyAvgDataResponseType;
  avgData: CompanyAvgDataResponseType;
};

const demoData: demoDataType = {
  prevData: {
    companyId: "b6d7e442-38ae-4b9a-b100-2951729768bc",
    conditions: [],
    startDate: "2022-01-11T00:00:00Z",
    endDate: "2022-01-17T00:00:00Z",
    summary: {
      responseTimeForFirstMessages: "00:20:08",
      responseTimeForAllMessages: "00:10:03.4285714",
      numberOfContacts: 538,
      numberOfContactsAverage: 6,
      numberOfAllConversations: 754,
      numberOfAllConversationsAverage: 6,
      numberOfActiveConversations: 672,
      numberOfActiveConversationsAverage: 342,
      numberOfNewEnquires: 353,
      numberOfNewEnquiresAverage: 4,
      numberOfMessagesSent: 23680,
      numberOfMessagesSentAverage: 14,
      numberOfMessageReceived: 15041,
      numberOfMessageReceivedAverage: 4,
      numberOfAutomatedMessages: 4,
      numberOfBroadcastSent: 756,
      numberOfBroadcastBounced: 0,
      numberOfBroadcastDelivered: 3,
      numberOfBroadcastRead: 845,
      numberOfBroadcastReplied: 0,
      activeAgents: 1,
      numberOfUniqueActiveConversations: 528,
      numberOfUniqueActiveConversationAverage: 487,
      numberOfUniqueConversations: 742,
      numberOfUniqueConversationAverage: 6,
    },
    dailyLogs: [
      {
        dateTime: "2022-01-11T00:00:00Z",
        responseTimeForFirstMessages: "00:00:00",
        responseTimeForAllMessages: "00:01:00",
        numberOfContacts: 7,
        numberOfContactsAverage: 1,
        numberOfAllConversations: 2,
        numberOfAllConversationsAverage: 2,
        numberOfActiveConversations: 0,
        numberOfActiveConversationsAverage: 0,
        numberOfNewEnquires: 8,
        numberOfNewEnquiresAverage: 1,
        numberOfMessagesSent: 10,
        numberOfMessagesSentAverage: 10,
        numberOfMessageReceived: 10,
        numberOfMessageReceivedAverage: 0,
        numberOfAutomatedMessages: 4,
        numberOfBroadcastSent: 0,
        numberOfBroadcastBounced: 0,
        numberOfBroadcastDelivered: 0,
        numberOfBroadcastRead: 0,
        numberOfBroadcastReplied: 0,
        activeAgents: 1,
        numberOfUniqueActiveConversations: 10,
        numberOfUniqueActiveConversationAverage: 0,
        numberOfUniqueConversations: 20,
        numberOfUniqueConversationAverage: 2,
      },
      {
        dateTime: "2022-01-12T00:00:00Z",
        responseTimeForFirstMessages: "00:00:00",
        responseTimeForAllMessages: "00:10:00",
        numberOfContacts: 12,
        numberOfContactsAverage: 1,
        numberOfAllConversations: 2,
        numberOfAllConversationsAverage: 2,
        numberOfActiveConversations: 1,
        numberOfActiveConversationsAverage: 1,
        numberOfNewEnquires: 5,
        numberOfNewEnquiresAverage: 0,
        numberOfMessagesSent: 13,
        numberOfMessagesSentAverage: 3,
        numberOfMessageReceived: 12,
        numberOfMessageReceivedAverage: 2,
        numberOfAutomatedMessages: 0,
        numberOfBroadcastSent: 3,
        numberOfBroadcastBounced: 0,
        numberOfBroadcastDelivered: 3,
        numberOfBroadcastRead: 0,
        numberOfBroadcastReplied: 0,
        activeAgents: 1,
        numberOfUniqueActiveConversations: 14,
        numberOfUniqueActiveConversationAverage: 1,
        numberOfUniqueConversations: 15,
        numberOfUniqueConversationAverage: 2,
      },
      {
        dateTime: "2022-01-13T00:00:00Z",
        responseTimeForFirstMessages: "00:00:00",
        responseTimeForAllMessages: "00:08:00",
        numberOfContacts: 18,
        numberOfContactsAverage: 2,
        numberOfAllConversations: 0,
        numberOfAllConversationsAverage: 0,
        numberOfActiveConversations: 0,
        numberOfActiveConversationsAverage: 0,
        numberOfNewEnquires: 10,
        numberOfNewEnquiresAverage: 2,
        numberOfMessagesSent: 16,
        numberOfMessagesSentAverage: 0,
        numberOfMessageReceived: 10,
        numberOfMessageReceivedAverage: 0,
        numberOfAutomatedMessages: 0,
        numberOfBroadcastSent: 0,
        numberOfBroadcastBounced: 0,
        numberOfBroadcastDelivered: 0,
        numberOfBroadcastRead: 0,
        numberOfBroadcastReplied: 0,
        activeAgents: 0,
        numberOfUniqueActiveConversations: 10,
        numberOfUniqueActiveConversationAverage: 0,
        numberOfUniqueConversations: 10,
        numberOfUniqueConversationAverage: 0,
      },
      {
        dateTime: "2022-01-14T00:00:00Z",
        responseTimeForFirstMessages: "00:00:00",
        responseTimeForAllMessages: "00:00:30",
        numberOfContacts: 14,
        numberOfContactsAverage: 1,
        numberOfAllConversations: 0,
        numberOfAllConversationsAverage: 0,
        numberOfActiveConversations: 0,
        numberOfActiveConversationsAverage: 0,
        numberOfNewEnquires: 13,
        numberOfNewEnquiresAverage: 0,
        numberOfMessagesSent: 19,
        numberOfMessagesSentAverage: 0,
        numberOfMessageReceived: 15,
        numberOfMessageReceivedAverage: 0,
        numberOfAutomatedMessages: 0,
        numberOfBroadcastSent: 0,
        numberOfBroadcastBounced: 0,
        numberOfBroadcastDelivered: 0,
        numberOfBroadcastRead: 0,
        numberOfBroadcastReplied: 0,
        activeAgents: 0,
        numberOfUniqueActiveConversations: 15,
        numberOfUniqueActiveConversationAverage: 0,
        numberOfUniqueConversations: 14,
        numberOfUniqueConversationAverage: 0,
      },
      {
        dateTime: "2022-01-15T00:00:00Z",
        responseTimeForFirstMessages: "00:00:00",
        responseTimeForAllMessages: "00:04:00",
        numberOfContacts: 19,
        numberOfContactsAverage: 0,
        numberOfAllConversations: 0,
        numberOfAllConversationsAverage: 0,
        numberOfActiveConversations: 0,
        numberOfActiveConversationsAverage: 0,
        numberOfNewEnquires: 9,
        numberOfNewEnquiresAverage: 0,
        numberOfMessagesSent: 10,
        numberOfMessagesSentAverage: 0,
        numberOfMessageReceived: 10,
        numberOfMessageReceivedAverage: 0,
        numberOfAutomatedMessages: 0,
        numberOfBroadcastSent: 0,
        numberOfBroadcastBounced: 0,
        numberOfBroadcastDelivered: 0,
        numberOfBroadcastRead: 0,
        numberOfBroadcastReplied: 0,
        activeAgents: 0,
        numberOfUniqueActiveConversations: 17,
        numberOfUniqueActiveConversationAverage: 0,
        numberOfUniqueConversations: 17,
        numberOfUniqueConversationAverage: 0,
      },
      {
        dateTime: "2022-01-16T00:00:00Z",
        responseTimeForFirstMessages: "00:00:00",
        responseTimeForAllMessages: "00:00:40",
        numberOfContacts: 20,
        numberOfContactsAverage: 1,
        numberOfAllConversations: 1,
        numberOfAllConversationsAverage: 1,
        numberOfActiveConversations: 1,
        numberOfActiveConversationsAverage: 1,
        numberOfNewEnquires: 10,
        numberOfNewEnquiresAverage: 1,
        numberOfMessagesSent: 16,
        numberOfMessagesSentAverage: 0,
        numberOfMessageReceived: 11,
        numberOfMessageReceivedAverage: 1,
        numberOfAutomatedMessages: 0,
        numberOfBroadcastSent: 0,
        numberOfBroadcastBounced: 0,
        numberOfBroadcastDelivered: 0,
        numberOfBroadcastRead: 0,
        numberOfBroadcastReplied: 0,
        activeAgents: 0,
        numberOfUniqueActiveConversations: 14,
        numberOfUniqueActiveConversationAverage: 1,
        numberOfUniqueConversations: 14,
        numberOfUniqueConversationAverage: 1,
      },
      {
        dateTime: "2022-01-17T00:00:00Z",
        responseTimeForFirstMessages: "00:00:00",
        responseTimeForAllMessages: "00:00:24",
        numberOfContacts: 21,
        numberOfContactsAverage: 0,
        numberOfAllConversations: 1,
        numberOfAllConversationsAverage: 1,
        numberOfActiveConversations: 1,
        numberOfActiveConversationsAverage: 1,
        numberOfNewEnquires: 14,
        numberOfNewEnquiresAverage: 0,
        numberOfMessagesSent: 20,
        numberOfMessagesSentAverage: 1,
        numberOfMessageReceived: 16,
        numberOfMessageReceivedAverage: 1,
        numberOfAutomatedMessages: 0,
        numberOfBroadcastSent: 0,
        numberOfBroadcastBounced: 0,
        numberOfBroadcastDelivered: 0,
        numberOfBroadcastRead: 0,
        numberOfBroadcastReplied: 0,
        activeAgents: 0,
        numberOfUniqueActiveConversations: 17,
        numberOfUniqueActiveConversationAverage: 1,
        numberOfUniqueConversations: 18,
        numberOfUniqueConversationAverage: 1,
      },
    ],
  },
  avgData: {
    companyId: "b6d7e442-38ae-4b9a-b100-2951729768bc",
    conditions: [],
    startDate: "2022-01-17T00:00:00Z",
    endDate: "2022-01-23T00:00:00Z",
    summary: {
      responseTimeForFirstMessages: "00:12:46.7142857",
      responseTimeForAllMessages: "00:03:33.1428571",
      numberOfContacts: 1448,
      numberOfContactsAverage: 5,
      numberOfAllConversations: 1732,
      numberOfAllConversationsAverage: 4,
      numberOfActiveConversations: 1430,
      numberOfActiveConversationsAverage: 3,
      numberOfNewEnquires: 654,
      numberOfNewEnquiresAverage: 4,
      numberOfMessagesSent: 37453,
      numberOfMessagesSentAverage: 12,
      numberOfMessageReceived: 24530,
      numberOfMessageReceivedAverage: 9,
      numberOfAutomatedMessages: 1,
      numberOfBroadcastSent: 8,
      numberOfBroadcastBounced: 1,
      numberOfBroadcastDelivered: 7,
      numberOfBroadcastRead: 1306,
      numberOfBroadcastReplied: 0,
      activeAgents: 3,
      numberOfUniqueActiveConversations: 1240,
      numberOfUniqueActiveConversationAverage: 3,
      numberOfUniqueConversations: 1473,
      numberOfUniqueConversationAverage: 4,
    },
    dailyLogs: [
      {
        dateTime: "2022-01-17T00:00:00Z",
        responseTimeForFirstMessages: "00:20:00",
        responseTimeForAllMessages: "00:10:24",
        numberOfContacts: 20,
        numberOfContactsAverage: 0,
        numberOfAllConversations: 1,
        numberOfAllConversationsAverage: 1,
        numberOfActiveConversations: 1,
        numberOfActiveConversationsAverage: 1,
        numberOfNewEnquires: 10,
        numberOfNewEnquiresAverage: 0,
        numberOfMessagesSent: 15,
        numberOfMessagesSentAverage: 1,
        numberOfMessageReceived: 15,
        numberOfMessageReceivedAverage: 1,
        numberOfAutomatedMessages: 0,
        numberOfBroadcastSent: 0,
        numberOfBroadcastBounced: 0,
        numberOfBroadcastDelivered: 0,
        numberOfBroadcastRead: 0,
        numberOfBroadcastReplied: 0,
        activeAgents: 0,
        numberOfUniqueActiveConversations: 20,
        numberOfUniqueActiveConversationAverage: 1,
        numberOfUniqueConversations: 20,
        numberOfUniqueConversationAverage: 1,
      },
      {
        dateTime: "2022-01-18T00:00:00Z",
        responseTimeForFirstMessages: "00:09:27",
        responseTimeForAllMessages: "00:05:28",
        numberOfContacts: 22,
        numberOfContactsAverage: 4,
        numberOfAllConversations: 6,
        numberOfAllConversationsAverage: 2,
        numberOfActiveConversations: 6,
        numberOfActiveConversationsAverage: 2,
        numberOfNewEnquires: 20,
        numberOfNewEnquiresAverage: 4,
        numberOfMessagesSent: 30,
        numberOfMessagesSentAverage: 10,
        numberOfMessageReceived: 20,
        numberOfMessageReceivedAverage: 9,
        numberOfAutomatedMessages: 1,
        numberOfBroadcastSent: 8,
        numberOfBroadcastBounced: 1,
        numberOfBroadcastDelivered: 7,
        numberOfBroadcastRead: 6,
        numberOfBroadcastReplied: 0,
        activeAgents: 3,
        numberOfUniqueActiveConversations: 26,
        numberOfUniqueActiveConversationAverage: 2,
        numberOfUniqueConversations: 17,
        numberOfUniqueConversationAverage: 2,
      },
      {
        dateTime: "2022-01-19T00:00:00Z",
        responseTimeForFirstMessages: "00:00:00",
        responseTimeForAllMessages: "00:00:00",
        numberOfContacts: 26,
        numberOfContactsAverage: 0,
        numberOfAllConversations: 5,
        numberOfAllConversationsAverage: 0,
        numberOfActiveConversations: 0,
        numberOfActiveConversationsAverage: 0,
        numberOfNewEnquires: 17,
        numberOfNewEnquiresAverage: 0,
        numberOfMessagesSent: 24,
        numberOfMessagesSentAverage: 0,
        numberOfMessageReceived: 17,
        numberOfMessageReceivedAverage: 0,
        numberOfAutomatedMessages: 0,
        numberOfBroadcastSent: 0,
        numberOfBroadcastBounced: 0,
        numberOfBroadcastDelivered: 0,
        numberOfBroadcastRead: 0,
        numberOfBroadcastReplied: 0,
        activeAgents: 0,
        numberOfUniqueActiveConversations: 29,
        numberOfUniqueActiveConversationAverage: 0,
        numberOfUniqueConversations: 28,
        numberOfUniqueConversationAverage: 0,
      },
      {
        dateTime: "2022-01-20T00:00:00Z",
        responseTimeForFirstMessages: "00:00:00",
        responseTimeForAllMessages: "00:3:00",
        numberOfContacts: 23,
        numberOfContactsAverage: 2,
        numberOfAllConversations: 0,
        numberOfAllConversationsAverage: 0,
        numberOfActiveConversations: 0,
        numberOfActiveConversationsAverage: 0,
        numberOfNewEnquires: 24,
        numberOfNewEnquiresAverage: 0,
        numberOfMessagesSent: 26,
        numberOfMessagesSentAverage: 0,
        numberOfMessageReceived: 20,
        numberOfMessageReceivedAverage: 0,
        numberOfAutomatedMessages: 0,
        numberOfBroadcastSent: 0,
        numberOfBroadcastBounced: 0,
        numberOfBroadcastDelivered: 0,
        numberOfBroadcastRead: 0,
        numberOfBroadcastReplied: 0,
        activeAgents: 0,
        numberOfUniqueActiveConversations: 27,
        numberOfUniqueActiveConversationAverage: 0,
        numberOfUniqueConversations: 25,
        numberOfUniqueConversationAverage: 0,
      },
      {
        dateTime: "2022-01-21T00:00:00Z",
        responseTimeForFirstMessages: "00:00:00",
        responseTimeForAllMessages: "00:02:00",
        numberOfContacts: 29,
        numberOfContactsAverage: 0,
        numberOfAllConversations: 0,
        numberOfAllConversationsAverage: 0,
        numberOfActiveConversations: 0,
        numberOfActiveConversationsAverage: 0,
        numberOfNewEnquires: 28,
        numberOfNewEnquiresAverage: 0,
        numberOfMessagesSent: 29,
        numberOfMessagesSentAverage: 0,
        numberOfMessageReceived: 23,
        numberOfMessageReceivedAverage: 0,
        numberOfAutomatedMessages: 0,
        numberOfBroadcastSent: 0,
        numberOfBroadcastBounced: 0,
        numberOfBroadcastDelivered: 0,
        numberOfBroadcastRead: 0,
        numberOfBroadcastReplied: 0,
        activeAgents: 0,
        numberOfUniqueActiveConversations: 33,
        numberOfUniqueActiveConversationAverage: 0,
        numberOfUniqueConversations: 30,
        numberOfUniqueConversationAverage: 0,
      },
      {
        dateTime: "2022-01-22T00:00:00Z",
        responseTimeForFirstMessages: "00:00:00",
        responseTimeForAllMessages: "00:00:08",
        numberOfContacts: 30,
        numberOfContactsAverage: 0,
        numberOfAllConversations: 0,
        numberOfAllConversationsAverage: 0,
        numberOfActiveConversations: 0,
        numberOfActiveConversationsAverage: 0,
        numberOfNewEnquires: 29,
        numberOfNewEnquiresAverage: 0,
        numberOfMessagesSent: 30,
        numberOfMessagesSentAverage: 0,
        numberOfMessageReceived: 26,
        numberOfMessageReceivedAverage: 0,
        numberOfAutomatedMessages: 0,
        numberOfBroadcastSent: 0,
        numberOfBroadcastBounced: 0,
        numberOfBroadcastDelivered: 0,
        numberOfBroadcastRead: 0,
        numberOfBroadcastReplied: 0,
        activeAgents: 0,
        numberOfUniqueActiveConversations: 34,
        numberOfUniqueActiveConversationAverage: 0,
        numberOfUniqueConversations: 33,
        numberOfUniqueConversationAverage: 0,
      },
      {
        dateTime: "2022-01-23T00:00:00Z",
        responseTimeForFirstMessages: "00:00:00",
        responseTimeForAllMessages: "00:00:10",
        numberOfContacts: 32,
        numberOfContactsAverage: 0,
        numberOfAllConversations: 3,
        numberOfAllConversationsAverage: 3,
        numberOfActiveConversations: 0,
        numberOfActiveConversationsAverage: 0,
        numberOfNewEnquires: 32,
        numberOfNewEnquiresAverage: 0,
        numberOfMessagesSent: 31,
        numberOfMessagesSentAverage: 3,
        numberOfMessageReceived: 27,
        numberOfMessageReceivedAverage: 0,
        numberOfAutomatedMessages: 0,
        numberOfBroadcastSent: 0,
        numberOfBroadcastBounced: 0,
        numberOfBroadcastDelivered: 0,
        numberOfBroadcastRead: 0,
        numberOfBroadcastReplied: 0,
        activeAgents: 1,
        numberOfUniqueActiveConversations: 38,
        numberOfUniqueActiveConversationAverage: 0,
        numberOfUniqueConversations: 38,
        numberOfUniqueConversationAverage: 3,
      },
    ],
  },
};

export default demoData;
