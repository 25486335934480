import React from "react";

export default function AuthorityIcon() {
  return (
    <svg width={14} height={17} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.41 10.04a1.874 1.874 0 00-1.87-1.734H8.11a.42.42 0 01-.425-.425v-.34c0-.73.272-1.445.8-2.09a3.044 3.044 0 00.662-2.364C8.961 1.761 7.856.69 6.513.537a2.963 2.963 0 00-2.38.731 3.03 3.03 0 00-1.02 2.261 3 3 0 00.63 1.836c.577.748.866 1.581.866 2.414v.102a.42.42 0 01-.425.425H2.756c-1.037 0-1.887.85-1.887 1.888v1.682c0 .222.187.409.408.409h.204v.781c0 .63.51 1.122 1.122 1.122h4.794a3.36 3.36 0 003.23 2.466 3.345 3.345 0 003.35-3.35 3.373 3.373 0 00-2.568-3.264zm-9.708 1.412v-1.275a1.06 1.06 0 011.054-1.055h1.428c.697 0 1.258-.56 1.258-1.258v-.102c0-1.003-.357-2.023-1.037-2.907a2.237 2.237 0 01-.459-1.326c0-.629.272-1.224.731-1.649a2.23 2.23 0 011.734-.544c.97.12 1.768.884 1.904 1.853a2.212 2.212 0 01-.476 1.717c-.646.782-1.003 1.683-1.003 2.601v.34c0 .697.561 1.258 1.258 1.258h1.428c.51 0 .918.357 1.037.816a3.285 3.285 0 00-2.72 1.514H1.702v.016zm.901 1.92a.305.305 0 01-.306-.306v-.782h5.134a3.367 3.367 0 00-.153 1.02v.069H2.603zm8.024 2.448a2.54 2.54 0 01-2.533-2.533 2.54 2.54 0 012.533-2.533 2.54 2.54 0 012.533 2.534 2.528 2.528 0 01-2.533 2.532z"
        fill="#fff"
      />
    </svg>
  );
}
