import React from "react";
export default function SeePassword() {
  return (
    <svg width={21} height={17} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)" fill="#9AA8BD">
        <path d="M10.5 3.782C4.998 3.782.525 9.137.525 9.137s4.473 5.355 9.975 5.355 9.975-5.355 9.975-5.355-4.473-5.355-9.975-5.355Zm0 8.819c-1.89 0-3.423-1.551-3.423-3.464 0-1.912 1.533-3.464 3.423-3.464 1.89 0 3.423 1.552 3.423 3.464 0 1.913-1.533 3.464-3.423 3.464Z" />
        <path d="M10.5 10.944c.986 0 1.785-.808 1.785-1.806 0-.997-.8-1.806-1.785-1.806-.986 0-1.785.809-1.785 1.806 0 .998.8 1.806 1.785 1.806Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h21v17H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
