import { RelativeTimeSpec } from "moment";

const relativeTime: Record<string, RelativeTimeSpec> = {
  "en-US": {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    ss: "%d seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    w: "a week",
    ww: "%d weeks",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
  "zh-HK": {
    future: "%s内",
    past: "%s之前",
    s: "幾秒",
    ss: "%d 秒",
    m: "1 分鐘",
    mm: "%d 分鐘",
    h: "1 小時",
    hh: "%d 小時",
    d: "1 日",
    dd: "%d 日",
    w: "1 星期",
    ww: "%d 星期",
    M: "1 個月",
    MM: "%d 個月",
    y: "1 年",
    yy: "%d 年",
  },
  "zh-CN": {
    future: "%s内",
    past: "%s之前",
    s: "几秒",
    ss: "%d 秒",
    m: "1 分钟",
    mm: "%d 分钟",
    h: "1 小时",
    hh: "%d 小时",
    d: "1 日",
    dd: "%d 日",
    w: "1 星期",
    ww: "%d 星期",
    M: "1 个月",
    MM: "%d 个月",
    y: "1 年",
    yy: "%d 年",
  },
  "id-ID": {
    future: "dalam %s",
    past: "%s yang lalu",
    s: "detik",
    ss: "%d detik",
    m: "1 menit",
    mm: "%d menit",
    h: "1 jam",
    hh: "%d jam",
    d: "1 hari",
    dd: "%d hari",
    w: "1 minggu",
    ww: "%d minggu",
    M: "1 bulan",
    MM: "%d bulan",
    y: "1 tahun",
    yy: "%d tahun",
  },
  "pt-BR": {
    future: "em %s",
    past: "%s atrás",
    s: "alguns segundos",
    ss: "%d segundos",
    m: "1 minuto",
    mm: "%d minutos",
    h: "%1 hora",
    hh: "%d horas",
    d: "1 dia",
    dd: "%d dias",
    w: "1 semana",
    ww: "%d semana",
    M: "1 mês",
    MM: "%d meses",
    y: "1 ano",
    yy: "%d anos",
  },
  "de-DE": {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    ss: "%d seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    w: "a week",
    ww: "%d weeks",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
  "it-IT": {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    ss: "%d seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    w: "a week",
    ww: "%d weeks",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
};

export default relativeTime;
