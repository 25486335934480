import React from "react";
import { WhatsappTemplateAction } from "container/Settings/OfficialWhatsApp/whatsappTemplateReducer";
import { Table } from "semantic-ui-react";
import { TableHeader } from "../Twilio/SettingTemplatesTable/TableHeader";
import TableRow from "./TableRow";
import { WhatsappCloudAPITemplateType } from "features/WhatsappCloudAPI/models/WhatsappCloudAPITemplateType";

export default function TemplateTable(props: {
  tabId: string;
  templates: readonly WhatsappCloudAPITemplateType[];
  checkableItems: readonly string[];
  dispatch: React.Dispatch<
    WhatsappTemplateAction<WhatsappCloudAPITemplateType>
  >;
  bookmarksPending: string[];
  toggleBookmark: (sid: string) => void;
}) {
  const {
    templates,
    checkableItems,
    dispatch,
    tabId,
    bookmarksPending,
    toggleBookmark,
  } = props;
  let bookmarkedCount = templates.filter(
    (t) => t.is_template_bookmarked
  ).length;

  return (
    <Table basic={"very"} className={"app data-grid"}>
      <TableHeader bookmarkedCount={bookmarkedCount} />
      <Table.Body>
        {[...templates].map((template) => (
          <TableRow
            tabId={tabId}
            toggleBookmark={toggleBookmark}
            dispatch={dispatch}
            checkableItems={checkableItems}
            template={template}
            bookmarkPending={bookmarksPending.includes(template.id)}
          />
        ))}
      </Table.Body>
    </Table>
  );
}
