import React from "react";

export default function UserIcon() {
  return (
    <svg width={17} height={18} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2 17.1a.9.9 0 11-1.8 0v-1.8a2.7 2.7 0 00-2.7-2.7H4.5a2.7 2.7 0 00-2.7 2.7v1.8a.9.9 0 01-1.8 0v-1.8a4.5 4.5 0 014.5-4.5h7.2a4.5 4.5 0 014.5 4.5v1.8zM8.1 9a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm0-1.8a2.7 2.7 0 100-5.4 2.7 2.7 0 000 5.4z"
        fill="#3C4257"
      />
    </svg>
  );
}
