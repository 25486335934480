export const DropdownType = [
  "options",
  "travisuser",
  "channel",
  "importfrom",
  "collaborators",
];

export const CustomFieldTypeList = [
  "singlelinetext",
  "multilinetext",
  "number",
  "phonenumber",
  "email",
  "options",
  "boolean",
  "datetime",
  "date",
  "travisuser",
  "channel",
];
