import React from "react";

function CloseIconCircle(props: { hovered: boolean }) {
  const color = props.hovered ? "#3C4257" : "#1A1F36";
  return (
    <svg
      width="8px"
      height="8px"
      viewBox="0 0 8 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Collaborator-&amp;-Inbox"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="New-Inbox-4.1---Collaborator"
          transform="translate(-823.000000, -89.000000)"
        >
          <g id="Group-19" transform="translate(823.000000, 89.000000)">
            <circle id="Oval" fill={color} cx="4" cy="4" r="4" />
            <g
              id="close"
              transform="translate(2.000000, 2.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <path
                d="M2.35233333,1.99883333 L3.92433333,0.426833333 C4.022,0.32925 4.022,0.170916667 3.92433333,0.0733333333 C3.82666667,-0.0243333333 3.6685,-0.0243333333 3.57083333,0.0733333333 L1.99883333,1.64533333 L0.42675,0.0733333333 C0.329083333,-0.0243333333 0.170916667,-0.0243333333 0.07325,0.0733333333 C-0.0244166667,0.170916667 -0.0244166667,0.32925 0.07325,0.426833333 L1.64533333,1.99883333 L0.07325,3.57083333 C-0.0244166667,3.66841667 -0.0244166667,3.82675 0.07325,3.92433333 C0.122083333,3.97308333 0.186083333,3.9975 0.25,3.9975 C0.313916667,3.9975 0.377916667,3.97308333 0.42675,3.92425 L1.99883333,2.35225 L3.57083333,3.92425 C3.61966667,3.97308333 3.68366667,3.9975 3.74758333,3.9975 C3.8115,3.9975 3.8755,3.97308333 3.92433333,3.92425 C4.022,3.82666667 4.022,3.66833333 3.92433333,3.57075 L2.35233333,1.99883333 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CloseIconCircle;
