import React from "react";

const CheckIcon = () => {
  return (
    <svg
      width="18px"
      height="16px"
      viewBox="0 0 18 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-3-6h24v24H-3z" />
        <path
          d="M6 10.17L2.53 6.7a.996.996 0 10-1.41 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L17.29 1.71A.996.996 0 1015.88.3L6 10.17z"
          fill="#1768FF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
export default CheckIcon;
