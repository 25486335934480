import {
  HASHTAG_FIELD_NAME,
  LIST_FIELD_NAME,
} from "config/ProfileFieldMapping";

export const AssignmentConditionFieldTypeMapping = {
  multilinetext: [
    "Contains",
    "IsNotContains",
    "IsNotNull",
    "IsNull",
    "IsChanged",
  ],
  singlelinetext: [
    "Contains",
    "IsNotContains",
    "IsNotNull",
    "IsNull",
    "IsChanged",
  ],
  email: ["Contains", "IsNotContains", "IsNotNull", "IsNull", "IsChanged"],
  phonenumber: [
    "Contains",
    "IsNotContains",
    "IsNotNull",
    "IsNull",
    "IsChanged",
  ],
  boolean: ["Equals", "IsNotNull", "IsNull", "IsChanged"],
  datetime: [
    "IsToday",
    "DateBeforeDayAgo",
    "DateAfterDayAgo",
    "HigherThan",
    "LessThan",
    "Equals",
    "IsBetween",
    "IsNotBetween",
    "IsNotNull",
    "IsNull",
    "IsChanged",
    "DayOfWeek",
    "IsExactlyDaysAfter",
    "IsExactlyDaysBefore",
  ],
  date: [
    "IsToday",
    "DateBeforeDayAgo",
    "DateAfterDayAgo",
    "LessThan",
    "HigherThan",
    "Equals",
    "IsBetween",
    "IsNotBetween",
    "IsNotNull",
    "IsNull",
    "IsChanged",
    "IsExactlyDaysAfter",
    "IsExactlyDaysBefore",
  ],
  number: [
    "Equals",
    "HigherThan",
    "LessThan",
    "IsNotNull",
    "IsNull",
    "IsChanged",
  ],
  options: [
    "ContainsExactly",
    "IsNotContainsExactly",
    "IsNotNull",
    "IsNull",
    "IsChanged",
  ],
  channel: ["Contains", "IsNotNull", "IsNull", "IsChanged"],
  channelCondition: [
    "Equals",
    "Contains",
    "IsNotContains",
    "IsNotNull",
    "IsNull",
    "IsChanged",
  ],
  travisuser: ["Contains", "IsNotContains", "IsNotNull", "IsNull", "IsChanged"],
  [LIST_FIELD_NAME]: [
    "ContainsAll",
    "ContainsAny",
    "IsNotContainsAll",
    "IsNotContainsAny",
  ],
  keywords: ["Equals", "Contains"],
  regex: ["RegexMatched"],
  awaystatus: ["IsAway", "IsActive"],
  conversationstatus: ["Equals", "Contains", "IsNotContains"],
  language: ["Contains"],
  userlanguage: [
    "Contains",
    "IsNotContains",
    "IsNotNull",
    "IsNull",
    "IsChanged",
  ],
  [HASHTAG_FIELD_NAME]: [
    "ContainsAll",
    "ContainsAny",
    "IsNotContainsAll",
    "IsNotContainsAny",
  ],
} as const;
