import React from "react";

export default function PhoneNumberIcon() {
  return (
    <svg width={10} height={17} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.15 6.027V3.149c0-.687-.288-1.816.487-1.97.598-.111 1.329 0 1.948 0H6.42c.62 0 1.284-.045 1.904 0 .354.021.399 0 .51.31.11.31 0 .818 0 1.15 0 1.35.021 2.723.043 4.074.023 1.284.023 2.568.023 3.83 0 1.527.177 3.254-.067 4.782-.133.752-.398.686-1.04.686H2.744c-.465 0-1.372.2-1.572-.288-.11-.243 0-.797 0-1.062v-1.373-3.21C1.15 8.683 1.15 7.355 1.15 6.027c0-.709-1.107-.709-1.107 0v7.571c0 .753-.11 1.616.022 2.369.288 1.66 3.144 1.107 4.317 1.107h3.055c.554 0 1.307.11 1.816-.178.708-.42.664-1.284.686-1.992.022-.974.044-1.948.044-2.922.022-1.528.022-3.055 0-4.583 0-1.705-.044-3.41-.066-5.114 0-.664.133-1.572-.51-1.992-.53-.354-1.394-.244-1.992-.244-1.992 0-4.029-.11-6.021 0C.132.138.02 1.068.02 2.11c0 1.305-.022 2.611 0 3.918.022.708 1.13.708 1.13 0z"
        fill="#fff"
      />
    </svg>
  );
}
