import React from "react";

export default function ArrowRight() {
  return (
    <svg
      width="18"
      height="11"
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4771 5.11663L12.3505 0.687504C12.1154 0.484254 11.7599 0.510878 11.5574 0.745628C11.3541 0.980753 11.38 1.33588 11.6151 1.53913L15.5448 4.93363H1.5415C1.231 4.93363 0.979004 5.18525 0.979004 5.49613C0.979004 5.807 1.231 6.05863 1.5415 6.05863H15.6861L13.4463 8.05775L11.6965 9.4415C11.4531 9.63425 11.4115 9.98788 11.6043 10.2316C11.7153 10.3719 11.8799 10.445 12.046 10.445C12.1683 10.445 12.2913 10.4056 12.3948 10.3235L14.1696 8.918L17.4839 5.9615C17.6046 5.85388 17.6733 5.69938 17.6721 5.53738C17.6706 5.37575 17.6001 5.22238 17.4771 5.11663Z"
        fill="#6078FF"
      />
    </svg>
  );
}
