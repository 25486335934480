import React, { useEffect, useState } from "react";
import { Menu, MenuItemProps } from "semantic-ui-react";
import { useHistory } from "react-router";
import { useAccessRulesGuard } from "./hooks/useAccessRulesGuard";
import { useTranslation } from "react-i18next";
import useRouteConfig from "../../config/useRouteConfig";
import { useAppDispatch, useAppSelector } from "../../AppRootContext";
import { useFeaturesGuard } from "./hooks/useFeaturesGuard";
import { isAdminRole } from "./helpers/AccessRulesGuard";
import styles from "./SettingSidebar.module.css";

interface SidebarPropsType {
  updateMenuItem: Function;
  menuItem: string;
}

const MenuSubHeader = (props: {
  children: React.ReactNode;
  newSection?: boolean;
}) => {
  const { children, newSection } = props;
  return (
    <div
      className={`${styles.subHeader} ${newSection ? styles.newSection : ""}`}
    >
      {children}
    </div>
  );
};

export default function SettingSidebar(props: SidebarPropsType) {
  const { updateMenuItem, menuItem } = props;
  const isRequestedTwilio = useAppSelector((s) =>
    s.company?.twilioUsageRecords
      ? s.company.twilioUsageRecords.length > 0
      : false
  );
  const isRequested360dialog = useAppSelector((s) =>
    s.company?.whatsApp360DialogUsageRecords
      ? s.company.whatsApp360DialogUsageRecords.length > 0
      : false
  );

  const loggedInUserDetail = useAppSelector((s) => s.loggedInUserDetail);
  const loginDispatch = useAppDispatch();
  let history = useHistory();
  const accessGuard = useAccessRulesGuard();
  const isResellerClient = accessGuard.isResellerClient();
  const [activeItem, setActiveItem] = useState("");
  const { t } = useTranslation();
  const { routeTo } = useRouteConfig();
  const ableToUseOfficial = accessGuard.canUseOfficialWhatsapp();
  const ableToOptIn = accessGuard.canShowOptIn();
  const ableToTopUp =
    (isRequestedTwilio && accessGuard.isTwilioSubaccount()) ||
    accessGuard.isCloudAPIUsageRecordExist() ||
    (isRequested360dialog && accessGuard.is360DialogAccount());
  const featureGuard = useFeaturesGuard();
  let isAdmin = false;
  if (loggedInUserDetail) {
    isAdmin = isAdminRole(loggedInUserDetail);
  }

  const visibleExtension =
    featureGuard.canUseStripePayments() ||
    ((featureGuard.canUseSalesforceCrm() || featureGuard.canUseHubspotCrm()) &&
      isAdmin);

  const handleItemClick = (e: React.MouseEvent, data: MenuItemProps) => {
    e.stopPropagation();
    const { name } = data;
    if (name === "requestaccess") {
      return;
    } else if (name === "officialWhatsapp") {
      if (ableToUseOfficial) {
        history.push(routeTo("/settings/templates"));
      } else if (ableToTopUp) {
        history.push(routeTo("/settings/topup"));
      }
    } else if (name === "teams" && !featureGuard.canUseTeams()) {
      loginDispatch({
        type: "IS_DISPLAY_UPGRADE_PLAN_MODAL",
        isDisplayUpgradePlanModal: true,
      });
    } else if (name === "messagemanagement") {
      history.push(routeTo("/settings/inbox"));
    } else {
      const menuName = name || "";
      setActiveItem(menuName);
      updateMenuItem(menuName);
      if (menuName === "messagemanagement") {
        setActiveItem("labels");
        updateMenuItem("labels");
        history.push(routeTo(`/settings/inbox/labels`));
        return;
      }
      if (["labels", "quickreplies"].includes(menuName)) {
        history.push(routeTo(`/settings/inbox/${menuName}`));
        return;
      }

      history.push(routeTo(`/settings/${menuName}`));
    }
  };

  useEffect(() => {
    setActiveItem(menuItem);
  }, [props.menuItem]);
  const requestWhatsappLink = accessGuard.isPaid()
    ? routeTo("/request-whatsapp")
    : routeTo("/onboarding/contact-first");
  return (
    <Menu vertical className="sidebar">
      <Menu.Header as="h4">{t("nav.menu.settings.settings")}</Menu.Header>
      <Menu.Menu>
        <MenuSubHeader>{t("nav.menu.settings.general")}</MenuSubHeader>
        <Menu.Item
          as="a"
          name={"generalinfo"}
          active={activeItem === "generalinfo"}
          onClick={handleItemClick}
        >
          <span>{t("nav.menu.settings.profile")}</span>
        </Menu.Item>
        {accessGuard.canAccessSettings() && (
          <>
            <Menu.Item
              as="a"
              name={"usermanagement"}
              active={activeItem === "usermanagement"}
              onClick={handleItemClick}
            >
              <span>{t("nav.menu.settings.users")}</span>
            </Menu.Item>
            <Menu.Item
              as="a"
              name={"teams"}
              active={activeItem === "teams"}
              onClick={handleItemClick}
            >
              <span>{t("nav.menu.settings.teams")}</span>
            </Menu.Item>
            {isAdmin && !isResellerClient && (
              <Menu.Item
                as="a"
                name={"whatsappQrCode"}
                active={activeItem === "whatsappQrCode"}
                onClick={handleItemClick}
              >
                <span>{t("nav.menu.settings.whatsappQrCode")}</span>
              </Menu.Item>
            )}
            <Menu.Item
              as="a"
              name={"whatsappBackupChats"}
              active={activeItem === "whatsappBackupChats"}
              onClick={handleItemClick}
            >
              <span>{t("nav.menu.settings.whatsappBackupChats")}</span>
            </Menu.Item>
            <Menu.Item
              as="div"
              name="messagemanagement"
              active={[
                "messagemanagement",
                "inbox",
                "labels",
                "quickreplies",
              ].includes(activeItem)}
              onClick={handleItemClick}
            >
              <span>{t("nav.menu.settings.messageManagement")}</span>
              <Menu.Menu className="secondary">
                <Menu.Item
                  as="a"
                  name="inbox"
                  active={activeItem === "inbox"}
                  onClick={handleItemClick}
                >
                  <span className="text">{t("nav.menu.settings.inbox")}</span>
                </Menu.Item>
                <Menu.Item
                  as="a"
                  name="labels"
                  active={activeItem === "labels"}
                  onClick={handleItemClick}
                >
                  <span className="text">{t("nav.menu.settings.labels")}</span>
                </Menu.Item>
                <Menu.Item
                  as="a"
                  name="quickreplies"
                  active={activeItem === "quickreplies"}
                  onClick={handleItemClick}
                >
                  <span className="text">
                    {t("nav.menu.settings.quickReply")}
                  </span>
                </Menu.Item>
              </Menu.Menu>
            </Menu.Item>
            {(accessGuard.canAccessPlanAndSubscription() ||
              featureGuard.isShopifyAccount()) &&
              featureGuard.canShowPlanSubscription() &&
              !isResellerClient && (
                <Menu.Item
                  as="a"
                  name="plansubscription"
                  active={activeItem === "plansubscription"}
                  onClick={handleItemClick}
                >
                  <span>{t("nav.menu.settings.plan")}</span>
                </Menu.Item>
              )}
            {isAdmin && (
              <Menu.Item
                as="a"
                name="partnership"
                active={activeItem === "partnership"}
                onClick={handleItemClick}
              >
                <span>{t("nav.menu.settings.partnership")}</span>
              </Menu.Item>
            )}
            <MenuSubHeader newSection>
              {t("nav.menu.settings.channels")}
            </MenuSubHeader>
            <Menu.Item
              className="official-whatsapp"
              as="div"
              name="officialWhatsapp"
              active={[
                "officialWhatsapp",
                "templates",
                "opt-in",
                "topup",
              ].includes(activeItem)}
              onClick={handleItemClick}
            >
              <span>{t("nav.menu.settings.officialWhatsapp")}</span>
              <Menu.Menu className="secondary">
                {ableToUseOfficial && (
                  <>
                    <Menu.Item
                      as="a"
                      name={"templates"}
                      active={activeItem === "templates"}
                      onClick={!ableToUseOfficial ? undefined : handleItemClick}
                    >
                      <span className={!ableToUseOfficial ? "lock" : ""}>
                        <span className="text">
                          {t("nav.menu.settings.templateManager")}
                        </span>
                      </span>
                    </Menu.Item>
                    {ableToOptIn && (
                      <Menu.Item
                        as="a"
                        name={"opt-in"}
                        active={activeItem === "opt-in"}
                        onClick={handleItemClick}
                      >
                        <span className={!ableToUseOfficial ? "lock" : ""}>
                          <span className="text">
                            {t("nav.menu.settings.optInButton")}
                          </span>
                        </span>
                      </Menu.Item>
                    )}
                  </>
                )}
                {ableToTopUp === true && featureGuard.canShowTopUp() && (
                  <Menu.Item
                    as="a"
                    name={"topup"}
                    active={activeItem === "topup"}
                    onClick={ableToTopUp ? handleItemClick : undefined}
                  >
                    <span className={ableToTopUp ? "" : "lock"}>
                      <span className="text">
                        {t("nav.menu.settings.billing")}
                      </span>
                    </span>
                  </Menu.Item>
                )}
                {!accessGuard.canUseOfficialWhatsapp() && (
                  <Menu.Item
                    as="div"
                    name={"requestaccess"}
                    active={activeItem === "requestaccess"}
                    onClick={handleItemClick}
                  >
                    <a
                      href={requestWhatsappLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text"
                    >
                      {t("nav.menu.settings.requestAccess")}
                    </a>
                  </Menu.Item>
                )}
              </Menu.Menu>
            </Menu.Item>
            <Menu.Item
              as="a"
              name={"livechatwidget"}
              active={activeItem === "livechatwidget"}
              onClick={handleItemClick}
            >
              <span>{t("livechat.liveChatWidget")}</span>
            </Menu.Item>
          </>
        )}
      </Menu.Menu>
      <Menu.Menu>
        <MenuSubHeader newSection>
          {t("nav.menu.settings.extensions")}
        </MenuSubHeader>
        {featureGuard.canUseStripePayments() && (
          <Menu.Item
            as="a"
            name={"paymentlink"}
            active={activeItem === "paymentlink"}
            onClick={handleItemClick}
          >
            <span>{t("nav.menu.settings.paymentLink")}</span>
          </Menu.Item>
        )}
        {featureGuard.canUseSalesforceCrm() && isAdmin && (
          <Menu.Item
            as="a"
            name={"salesforce"}
            active={activeItem === "salesforce"}
            onClick={handleItemClick}
          >
            <span>{t("nav.menu.settings.salesforce")}</span>
          </Menu.Item>
        )}
        {featureGuard.canUseHubspotCrm() && isAdmin && (
          <Menu.Item
            as="a"
            name={"hubspot"}
            active={activeItem === "hubspot"}
            onClick={handleItemClick}
          >
            <span>{t("nav.menu.settings.hubspot")}</span>
          </Menu.Item>
        )}
        {isAdmin && (
          <Menu.Item
            as="a"
            name={"commerce"}
            active={activeItem === "commerce"}
            onClick={handleItemClick}
          >
            <span>{t("nav.menu.settings.commerce")}</span>
          </Menu.Item>
        )}
      </Menu.Menu>
    </Menu>
  );
}
