import React from "react";
export default function HidePassword() {
  return (
    <svg width={21} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.975 4.782C5.473 4.782 1 10.137 1 10.137s4.473 5.355 9.975 5.355 9.975-5.355 9.975-5.355-4.473-5.355-9.975-5.355Zm0 8.819c-1.89 0-3.423-1.551-3.423-3.464 0-1.912 1.533-3.464 3.423-3.464 1.89 0 3.423 1.552 3.423 3.464 0 1.913-1.533 3.464-3.423 3.464Z"
        fill="#9AA8BD"
      />
      <path
        d="M10.975 11.944c.5-.444 1-.944 1.785-1.806 0-.997-.8-1.806-1.785-1.806-.986 0-1.785.809-1.785 1.806 0 .998.8 1.806 1.785 1.806Z"
        fill="#9AA8BD"
      />
      <path
        d="m18.975 3-14.5 14.5"
        stroke="#9AA8BD"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
