import * as React from "react";

const Star = (props: { className?: string; solid?: boolean }) => {
  const { solid = false } = props;
  const solidSize = "18 18";
  const outlineSize = "30 30";
  return (
    <svg
      width={17}
      height={17}
      viewBox={`0 0 ${solid ? solidSize : outlineSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className ?? ""}
    >
      {solid && (
        <path
          d="M13.7244 18C13.4386 18 13.1556 17.9056 12.9154 17.7211L9.00281 14.7066L5.08956 17.7211C4.84869 17.9056 4.56494 18 4.28051 18C4.00017 18 3.71982 17.9084 3.48099 17.7267C2.99991 17.364 2.80054 16.7174 2.98903 16.1297L4.52683 11.3153L0.570038 8.36404C0.0869211 8.00276 -0.116532 7.35899 0.0665077 6.76987C0.248867 6.18431 0.772131 5.78896 1.36208 5.78896C1.3648 5.78896 1.36888 5.78896 1.3716 5.78896L6.2368 5.82942L7.70452 0.991561C7.8828 0.401735 8.40878 0 9.00145 0C9.59412 0 10.1208 0.401735 10.2977 0.991561L11.7647 5.82942L16.6272 5.78896C16.6299 5.78896 16.6374 5.78896 16.6401 5.78896C17.2274 5.78896 17.754 6.18431 17.9323 6.76987C18.1167 7.3597 17.916 8.00276 17.4322 8.36404L13.4774 11.3153L15.0173 16.1297C15.203 16.716 15.0037 17.3626 14.5267 17.7267C14.2872 17.9084 14.0048 18 13.7244 18Z"
          fill="var(--color, inherit)"
        />
      )}
      {!solid && (
        <path
          d="M29.293 11.4332C29.0013 10.4951 28.1664 9.86365 27.2227 9.86365H27.2053L19.4225 9.92725L17.079 2.18607C16.7916 1.24228 15.9524 0.600586 15.0043 0.600586C14.0562 0.600586 13.2137 1.24228 12.9296 2.18607L10.5817 9.92725L2.79894 9.86365H2.77935C1.83453 9.86365 0.998567 10.4951 0.706849 11.4332C0.412955 12.377 0.738416 13.406 1.51234 13.9841L7.84196 18.7065L5.38195 26.4091C5.08153 27.3494 5.40046 28.3841 6.16894 28.9645C6.551 29.2552 6.99946 29.4006 7.44792 29.4006C7.90291 29.4006 8.35573 29.2507 8.74215 28.9554L15.0021 24.1319L21.2621 28.9554C21.6474 29.2507 22.1013 29.4006 22.5552 29.4006C23.0037 29.4006 23.4543 29.2552 23.8374 28.9645C24.6037 28.3818 24.9227 27.3472 24.6223 26.4091L22.159 18.7065L28.4886 13.9841C29.2636 13.4083 29.5858 12.377 29.293 11.4332ZM19.5847 17.8354L22.5552 27.1302L15.001 21.3096L7.44574 27.1302L10.4163 17.8354L2.77609 12.1374L12.1666 12.2112L14.9999 2.87092L17.8344 12.2124L27.2249 12.1385L19.5847 17.8354Z"
          fill={"var(--color, inherit)"}
        />
      )}
    </svg>
  );
};

export default Star;
