import React from "react";

export default function ApprovalIcon() {
  return (
    <svg width={15} height={15} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.997.139l.663.482c.184.148.405.222.626.148l.774-.185c.331-.074.7.074.884.407l.405.704c.11.223.295.371.553.408l.736.148c.368.075.626.371.663.705l.074.815c.037.26.147.445.331.593l.663.445c.295.222.405.592.295.926l-.258.778a.837.837 0 00.074.667l.405.667c.184.334.147.705-.11.964l-.553.63a.802.802 0 00-.184.63l.073.778a.821.821 0 01-.478.853l-.737.333a.782.782 0 00-.442.519l-.258.741c-.073.334-.405.556-.773.556h-.81a.767.767 0 00-.59.297l-.516.556a.8.8 0 01-.957.222l-.737-.334a.701.701 0 00-.663 0l-.7.334a.8.8 0 01-.957-.223l-.553-.592a.665.665 0 00-.626-.26h-.773a.831.831 0 01-.774-.556l-.258-.815c-.073-.222-.22-.37-.442-.482l-.7-.296a.822.822 0 01-.478-.853l.073-.815c.037-.26-.037-.445-.22-.63L.225 8.81c-.258-.26-.295-.63-.11-.964l.404-.704c.11-.222.148-.444.074-.667l-.258-.741a.83.83 0 01.295-.926l.663-.482c.184-.148.331-.334.331-.593l.074-.778a.822.822 0 01.663-.705l.773-.185a.709.709 0 00.553-.408l.368-.667A.79.79 0 014.94.584l.81.185c.221.037.442 0 .663-.148l.59-.482a.959.959 0 01.994 0zM4.498 7.81a.472.472 0 010-.667.504.504 0 01.663.037l1.437 1.446 3.24-3.262a.465.465 0 01.664 0 .558.558 0 010 .668L6.929 9.626a.504.504 0 01-.663-.037L4.498 7.81z"
        fill="#fff"
      />
    </svg>
  );
}
