import { getWithExceptions } from "../apiRequest";
type DefaultTimezoneResponse = {
  id: string;
  displayName: string;
  standardName: string;
  baseUtcOffset: string;
  baseUtcOffsetInHour: number;
};

export const fetchDefaultTimezone =
  async (): Promise<DefaultTimezoneResponse> => {
    return await getWithExceptions("/timezone", {
      param: {},
      config: {
        skipAuth: true,
      },
    });
  };
