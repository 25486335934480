import * as React from "react";

const LightBulb = ({ className }: { className?: string }) => (
  <svg
    width={22}
    height={24}
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M10.395 2.593c-3.265.08-6.26 2.164-6.26 6.097 0 3.687 3.57 4.973 3.707 6.448h5.105c.137-1.475 3.708-2.761 3.708-6.448 0-3.933-2.996-6.018-6.26-6.097Zm2.494 9.55c-.386.38-.778.766-1.096 1.195H8.996c-.317-.43-.71-.815-1.096-1.194-.994-.977-1.933-1.9-1.933-3.454 0-3.07 2.375-4.23 4.427-4.297 2.053.066 4.427 1.227 4.427 4.297 0 1.555-.938 2.477-1.932 3.454ZM10.395 19.508c1.519 0 2.75-1.282 2.75-2.865h-5.5c0 1.583 1.23 2.864 2.75 2.864Z"
      fill={"var(--color, #3C4257)"}
    />
  </svg>
);

export default LightBulb;
