import React from "react";
import { WhatsappQrCode } from "./WhatsappQrCode";
import { useAppSelector } from "../../../AppRootContext";
import { InActivatedHint } from "./InActivatedHint";

const SettingWhatsappQrCode = () => {
  const isQRCodeMappingEnabled = useAppSelector(
    (s) => s.company?.isQRCodeMappingEnabled
  );

  return isQRCodeMappingEnabled ? <WhatsappQrCode /> : <InActivatedHint />;
};
export default SettingWhatsappQrCode;
