import React from "react";

function GroupIcon() {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.977 15.158c.565-.59.914-1.388.914-2.267a3.286 3.286 0 00-2.545-3.197 5.404 5.404 0 00-2.978-4.145 3.27 3.27 0 00.913-2.268A3.285 3.285 0 0010 0a3.285 3.285 0 00-3.281 3.281c0 .88.348 1.678.913 2.268a5.404 5.404 0 00-2.978 4.145A3.286 3.286 0 002.11 12.89c0 .879.349 1.678.913 2.267A5.397 5.397 0 000 20h1.563a3.832 3.832 0 013.828-3.828A3.832 3.832 0 019.219 20h1.562a3.832 3.832 0 013.828-3.828A3.832 3.832 0 0118.438 20H20a5.397 5.397 0 00-3.023-4.842zm-.649-2.267a1.72 1.72 0 01-1.719 1.718 1.72 1.72 0 01-1.718-1.718 1.72 1.72 0 011.718-1.72 1.72 1.72 0 011.72 1.72zM8.281 3.28A1.72 1.72 0 0110 1.562a1.72 1.72 0 011.719 1.72A1.72 1.72 0 0110 5a1.72 1.72 0 01-1.719-1.719zm-4.61 9.61a1.72 1.72 0 011.72-1.72 1.72 1.72 0 011.718 1.72 1.72 1.72 0 01-1.718 1.718 1.72 1.72 0 01-1.72-1.718zM10 17.207a5.428 5.428 0 00-2.241-2.049c.565-.59.913-1.388.913-2.267 0-1.52-1.038-2.8-2.441-3.172A3.834 3.834 0 0110 6.563a3.835 3.835 0 013.77 3.156 3.287 3.287 0 00-2.442 3.172c0 .879.348 1.678.913 2.267A5.429 5.429 0 0010 17.208z"
        fill="#3C4257"
      />
    </svg>
  );
}

export default GroupIcon;
