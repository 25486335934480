import { PlanDisplayType } from "../types/PlanSelectionType";

const selectionPlanList: PlanDisplayType[] = [
  {
    planId: "sleekflow_free",
    planTitle: "Free",
    price: "0",
    priceDescription: "Lifetime",
    planDetailDescriptions: [""],
    agentIncluded: 1,
    extraChatAgentPrice: 9,
    planDescriptions: [
      "200 Messages",
      "3 Chat Seats",
      "Unlimited Contacts",
      "Basic Support",
      "All Messaging Channels",
    ],
  },
  {
    planId: "sleekflow_pro",
    planTitle: "Professional",
    price: "39",
    agentIncluded: 1,
    extraChatAgentPrice: 9,
    priceDescription: "Month",
    planDetailDescriptions: [""],
    planDescriptions: [
      "5000 Messages",
      "10 Chat Seats",
      "Unlimited Contacts",
      "Basic Support",
      "All Messaging Channels",
    ],
  },
];
export const MonthlyPlanList: PlanDisplayType[] = [
  {
    planId: "sleekflow_freemium",
    planTitle: "Free Plan",
    price: "",
    agentIncluded: 3,
    extraChatAgentPrice: 0,
    messageTotal: 5000,
    priceDescription: "month",
    planDetailDescriptions: [
      "Mobile App",
      "All Messaging Channels",
      "Including 3 Staff Log In",
      "5000 Customer Contacts",
      "5000 Marketing Campaign Messages",
      "5 Assignment & Automation Rules",
      "User Role & Permissions",
    ],
    planDescriptions: [
      "3 Staff Log In",
      "All Messaging Channels",
      "Unlimited Send & Receive Messages",
      "100 Customer Contacts",
      "100 Campaign & Automated Messages",
    ],
  },
  {
    planId: "sleekflow_v3_pro",
    planTitle: "Pro Plan",
    price: "99",
    agentIncluded: 3,
    extraChatAgentPrice: 19,
    messageTotal: 5000,
    priceDescription: "month",
    planDetailDescriptions: [
      "Mobile App",
      "All Messaging Channels",
      "Including 3 Staff Log In",
      "5000 Customer Contacts",
      "5000 Marketing Campaign Messages",
      "5 Assignment & Automation Rules",
      "User Role & Permissions",
    ],
    planDescriptions: [
      "3 Staff Log In",
      "5000 Customer Contacts",
      "5000 Campaign & Auto Messages",
      "5 Automation Rules",
      "Zapier Integration",
    ],
  },
  {
    planId: "sleekflow_v4_premium_monthly",
    planTitle: "Premium Plan",
    price: "349",
    agentIncluded: 5,
    extraChatAgentPrice: 39,
    messageTotal: 20000,
    priceDescription: "month",
    planDetailDescriptions: [
      "5 Staff Log In",
      "Unlimited Customer Contacts",
      "20000 Marketing Campaign Messages",
      "Unlimited Assignment & Automation Rules",
      "Multiple Teams & User Groups",
      "Message & Contact API Integrations",
    ],
    planDescriptions: [
      "5 Staff Log In",
      "Unlimited Customer Contacts",
      "Unlimited Campaign & Auto Messages",
      "Unlimited Automation Rules",
      "User Teams & Availability Routing",
      "API Integration",
    ],
  },
];
export const YearlyPlanList: PlanDisplayType[] = [
  {
    planId: "sleekflow_v5_pro_yearly",
    planTitle: "Pro Plan",
    price: "79",
    agentIncluded: 3,
    extraChatAgentPrice: 19,
    messageTotal: 5000,
    priceDescription: "month",
    planDetailDescriptions: [
      "Mobile App",
      "All Messaging Channels",
      "Including 3 Staff Log In",
      "5000 Customer Contacts",
      "5000 Marketing Campaign Messages",
      "5 Assignment & Automation Rules",
      "User Role & Permissions",
    ],
    planDescriptions: [
      "3 Staff Log In",
      "5000 Customer Contacts",
      "5000 Campaign & Auto Messages",
      "5 Automation Rules",
      "Zapier Integration",
    ],
  },
  {
    planId: "sleekflow_v4_premium_yearly",
    planTitle: "Premium Plan",
    price: "299",
    agentIncluded: 5,
    extraChatAgentPrice: 39,
    messageTotal: 20000,
    priceDescription: "month",
    planDetailDescriptions: [
      "5 Staff Log In",
      "Unlimited Customer Contacts",
      "20000 Marketing Campaign Messages",
      "Unlimited Assignment & Automation Rules",
      "Multiple Teams & User Groups",
      "Message & Contact API Integrations",
    ],
    planDescriptions: [
      "5 Staff Log In",
      "Unlimited Customer Contacts",
      "Unlimited Campaign & Auto Messages",
      "Unlimited Automation Rules",
      "User Teams & Availability Routing",
      "API Integration",
    ],
  },
];
export const SettingSelectPlanList: PlanDisplayType[] = [
  {
    planId: "sleekflow_v2_standard",
    planTitle: "Standard",
    price: "19",
    messageTotal: 100,
    agentIncluded: 1,
    extraChatAgentPrice: 9,
    priceDescription: "month",
    planDetailDescriptions: [
      "Unlimited Send & Receive Messages",
      "3 Messaging Channels",
      "100 Campaign & Automated Messages",
      "500 Customer Contacts",
      "Team Inbox",
      "Website Messenger",
      "Mobile App",
    ],
    planDescriptions: [
      "All Messaging Channels",
      "100 Campaign Messages",
      "Welcome Messages & Auto-Reply",
    ],
  },
  {
    planId: "sleekflow_v2_pro",
    planTitle: "Pro",
    price: "79",
    agentIncluded: 3,
    extraChatAgentPrice: 19,
    messageTotal: 5000,
    priceDescription: "month",
    planDetailDescriptions: [
      "5 Messaging Channels",
      "5,000 Marketing Campaign Messages",
      "5,000 Customer Contacts",
      "5 Basic Assignment & Automation Rules",
      "User Role & Permissions",
      "Teams & Availability Routing",
    ],
    planDescriptions: [
      "5000 Campaign Messages",
      "Assignment Rule & Availability Routing",
      "Chat Support & 30-day onboarding",
    ],
  },
  {
    planId: "sleekflow_v2_premium",
    planTitle: "Premium",
    price: "299",
    agentIncluded: 5,
    extraChatAgentPrice: 39,
    messageTotal: 20000,
    priceDescription: "month",
    planDetailDescriptions: [
      "Unlimited Messaging Channels",
      "20,000 Marketing Campaign Messages",
      "Unlimited Customer Contacts",
      "Unlimited Assigngment & Automation Rules",
      "Multiple Teams & User Groups",
      "Staff Analytics & Reporting",
      "Dedicated Account Manager for Onboarding",
    ],
    planDescriptions: [
      "20000 Campaign Messages",
      "Multiple Teams & Role Based Access Control",
      "Dedicated Account Manager",
    ],
  },
  {
    planId: "sleekflow_v3_standard",
    planTitle: "Standard",
    price: "49",
    messageTotal: 100,
    agentIncluded: 1,
    extraChatAgentPrice: 9,
    priceDescription: "month",
    planDetailDescriptions: [
      "Unlimited Send & Receive Messages",
      "Unlimited Messaging Channels",
      "100 Marketing Campaign Messages",
      "500 Customer Contacts",
      "Team Inbox",
      "Website Messenger",
      "Mobile App",
    ],
    planDescriptions: [
      "Unlimited Messaging Channels",
      "500 Customer Contacts",
      "100 Marketing Campaign Messages",
    ],
  },
  {
    planId: "sleekflow_v3_pro",
    planTitle: "Pro",
    price: "99",
    agentIncluded: 3,
    extraChatAgentPrice: 19,
    messageTotal: 5000,
    priceDescription: "month",
    planDetailDescriptions: [
      "5,000 Marketing Campaign Messages",
      "5,000 Customer Contacts",
      "5 Basic Assignment & Automation Rules",
      "Real-Time Campaign Analytics",
      "User Role & Permissions",
      "Teams & Availability Routing",
    ],
    planDescriptions: [
      "5000 Customer Contacts",
      "5000 Marketing Campaign Messages",
      "5 Basic Assignment & Automation Rules",
    ],
  },
  {
    planId: "sleekflow_v3_premium",
    planTitle: "Premium",
    price: "299",
    agentIncluded: 5,
    extraChatAgentPrice: 39,
    messageTotal: 20000,
    priceDescription: "month",
    planDetailDescriptions: [
      "20,000 Marketing Campaign Messages",
      "Unlimited Customer Contacts",
      "Unlimited Assignment & Automation Rules",
      "Multiple Teams & User Groups",
      "Staff Analytics & Reporting",
      "Dedicated Account Manager for Onboarding",
    ],
    planDescriptions: [
      "Unlimited Customer Contacts",
      "20,000 Marketing Campaign Messages",
      "Unlimited Assignment & Automation Rules",
    ],
  },
];
