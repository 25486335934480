import React from "react";

export default function BookConsultationIcon() {
  return (
    <svg width={30} height={30} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.2 26.1a.9.9 0 11-1.8 0v-1.8a2.7 2.7 0 00-2.7-2.7H6.5a2.7 2.7 0 00-2.7 2.7v1.8a.9.9 0 01-1.8 0v-1.8a4.5 4.5 0 014.5-4.5h7.2a4.5 4.5 0 014.5 4.5v1.8zM10.1 18a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm0-1.8a2.7 2.7 0 100-5.4 2.7 2.7 0 000 5.4zM19.479 5.305c-1.195 0-2.163.939-2.163 2.106 0 .199.143.341.342.341.199 0 .341-.142.341-.341 0-.797.655-1.423 1.452-1.423.796 0 1.45.654 1.45 1.422 0 .769-.654 1.423-1.45 1.423-.2 0-.342.143-.342.342v1.024c0 .2.142.341.341.341.2 0 .342-.142.342-.34v-.712c1.024-.171 1.793-1.053 1.793-2.077.028-1.139-.94-2.106-2.106-2.106z"
        fill="#3C4257"
      />
      <path
        d="M22.581 3h-5.72C13.875 3 11.428 5.22 11 8.065c.284.057.54.142.825.228.313-2.504 2.447-4.468 5.037-4.468h5.748a5.088 5.088 0 015.093 5.094 5.088 5.088 0 01-5.093 5.093h-1.793l-3.3 2.447v-2.447h-.684c-.483 0-.939-.085-1.394-.2v.172c0 .227-.028.455-.028.682.398.114.825.171 1.252.171v3.273l4.439-3.273h1.48A5.914 5.914 0 0028.5 8.92C28.528 5.646 25.854 3 22.58 3z"
        fill="#3C4257"
      />
      <path
        d="M19.48 12.333a.54.54 0 100-1.08.54.54 0 000 1.08z"
        fill="#3C4257"
      />
    </svg>
  );
}
