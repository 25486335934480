import { getWithExceptions } from "api/apiRequest";
import { useAppDispatch, useAppSelector } from "AppRootContext";
import React, { useEffect, useState } from "react";

type Response = {
  isGlobalPricingFeatureEnabled: boolean;
};
export default function useFetchPlanFeatureInfo() {
  const globalPricingFeatureEnabled = useAppSelector(
    (s) => s.company?.isGlobalPricingFeatureEnabled
  );
  const companyId = useAppSelector((s) => s.company?.id ?? "");
  const dispatch = useAppDispatch();
  const [isGlobalPricingFeatureEnabled, setIsGlobalPricingFeatureEnabled] =
    useState(false);
  async function fetchCompanyPlanFeatureInfo() {
    const result: Response = await getWithExceptions("/app/feature-info", {
      param: {},
      config: {
        skipAuth: true,
      },
    });
    dispatch({
      type: "UPDATE_COMPANY_GLOBAL_PRICING_FEATURE_INFO",
      isGlobalPricingFeatureEnabled: result.isGlobalPricingFeatureEnabled,
    });
    setIsGlobalPricingFeatureEnabled(result.isGlobalPricingFeatureEnabled);
  }
  useEffect(() => {
    if (
      globalPricingFeatureEnabled === undefined ||
      (companyId && globalPricingFeatureEnabled === undefined)
    ) {
      fetchCompanyPlanFeatureInfo();
    }
  }, [globalPricingFeatureEnabled, companyId]);
  return {
    isGlobalPricingFeatureEnabled,
  };
}
