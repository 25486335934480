export const migrateProviders = Object.freeze([
  "Twilio",
  "Other",
  "8x8 Asia",
  "ACL Mobile Limited",
  "AhaChat",
  "Airy",
  "Aivo",
  "Ameyo",
  "Amplify.ai",
  "BotBonnie",
  "BotMaker",
  "Brand Embassy",
  "Bump Yard",
  "Chatfuel",
  "Chatisfy",
  "Clarabridge",
  "Clare AI",
  "Clickatell WA",
  "CM.com",
  "Conversocial",
  "Dealer Car Search - DCS",
  "Edna",
  "Emma Global - Hong Kong Limited",
  "Facelift Upljft",
  "Falcon.io",
  "Freshdesk",
  "Genesys",
  "GupShup Technology India Pvt Ltd",
  "Haptik",
  "Hashtag Group",
  "Hello Tech",
  "HubSpot Ads Team",
  "Hubtype",
  "IDO VN",
  "IMImobile",
  "Infobip",
  "JCODZ",
  "Kaleyra",
  "Kaojao",
  "Karix Mobile Pvt Ltd",
  "Khoros LLC",
  "Kit CRM",
  "Kokatto Teknologi Global",
  "Latticespace",
  "LiveEngage by LivePerson",
  "MACRO KIOSK Berhad",
  "ManyChat",
  "MessageBird",
  "MessengerPeople GmbH",
  "MobileMonkey",
  "Mutant",
  "Netcore Solutions",
  "Nhanh.vn",
  "NovaonX Chatbot",
  "Novomind AG",
  "Octane AI",
  "Omnichat Limited",
  "OneChat.ai",
  "Page365",
  "Pancake",
  "Positus Provider",
  "PT Digital Artha Media",
  "PT. Informasi Teknologi Indonesia",
  "Qiscus - Multichannel Conversational Platform",
  "Qontak.com",
  "RingCentral Engage Digital",
  "Sanuker Inc. Limited",
  "SendPulse",
  "Shoplus App",
  "Sinch",
  "Sinch LATAM",
  "SIRCLO",
  "smarters",
  "SmaxBot",
  "Sparkcentral",
  "SPE Gateway Co.",
  "Sprinklr",
  "Superare",
  "Take Blip",
  "Tap The Table LLC",
  "Turn.io",
  "Tyntec",
  "UIB Holdings Pte Ltd",
  "Unifonic Inc",
  "V Rich App",
  "Valuefirst",
  "Vonage Communication APIs - formerly Nexmo",
  "Yalo",
  "Yellow Messenger",
  "YesBoss",
  "Yoizen",
  "Zendesk",
  "Zenvia",
  "ZWIZ AI",
]);
