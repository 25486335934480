import React from "react";

export default function PricingIcon() {
  return (
    <svg width={17} height={17} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 1a7.5 7.5 0 100 15 7.5 7.5 0 000-15zm0 13.695a6.196 6.196 0 110-12.39 6.196 6.196 0 010 12.39z"
        fill="#fff"
      />
      <path
        d="M9.152 8.082V6.057c.316.079.607.234.848.454a.653.653 0 10.9-.946 3.365 3.365 0 00-1.748-.841v-.825a.652.652 0 10-1.304 0v.844c-.3.056-.59.158-.858.304a2.005 2.005 0 00-.447 3.352c.371.344.818.595 1.305.733v2.032A1.976 1.976 0 017 10.717a.653.653 0 00-.9.943 3.34 3.34 0 001.748.838v.678a.652.652 0 101.304 0v-.688c.308-.058.606-.161.884-.307a2.006 2.006 0 00.42-3.355 3.26 3.26 0 00-1.304-.744zm-1.705-.629a.858.858 0 01-.307-.681.861.861 0 01.473-.58c.075-.04.154-.073.235-.099v1.63c-.15-.069-.29-.16-.414-.27h.013zm2.426 3.003a.845.845 0 01-.473.578 1.42 1.42 0 01-.261.11V9.478c.156.074.3.17.427.287a.864.864 0 01.307.691z"
        fill="#fff"
      />
    </svg>
  );
}
