import React from "react";

function SearchIcon(props: { color?: string; size?: number }) {
  const { color = "#697386", size = 14 } = props;
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      version="1.1"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="search" fill={color} fillRule="nonzero">
          <path
            d="M13.5446316,12.7441754 L10.2145965,9.28077193 C11.070807,8.26294737 11.5399298,6.98231579 11.5399298,5.64912281 C11.5399298,2.53424561 9.00568421,0 5.89080702,0 C2.77592982,0 0.241684211,2.53424561 0.241684211,5.64912281 C0.241684211,8.764 2.77592982,11.2982456 5.89080702,11.2982456 C7.06017544,11.2982456 8.17452632,10.9455439 9.12726316,10.276 L12.4825965,13.7656842 C12.6228421,13.9113333 12.8114737,13.9916491 13.013614,13.9916491 C13.2049474,13.9916491 13.3864561,13.9187018 13.5242456,13.7860702 C13.8170175,13.5043509 13.8263509,13.037193 13.5446316,12.7441754 Z M5.89080702,1.47368421 C8.19319298,1.47368421 10.0662456,3.34673684 10.0662456,5.64912281 C10.0662456,7.95150877 8.19319298,9.8245614 5.89080702,9.8245614 C3.58842105,9.8245614 1.71536842,7.95150877 1.71536842,5.64912281 C1.71536842,3.34673684 3.58842105,1.47368421 5.89080702,1.47368421 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
}

export default SearchIcon;
