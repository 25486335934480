import { WhatsappTemplateNormalizedType } from "../../../../../types/WhatsappTemplateResponseType";
import React from "react";
import { WhatsappTemplateAction } from "../../../../../container/Settings/OfficialWhatsApp/whatsappTemplateReducer";
import { useTranslation } from "react-i18next";
import { Table } from "semantic-ui-react";
import { TableHeader } from "./TableHeader";
import { TemplateRow } from "./TemplateRow";
import { byBookmarkedStatus } from "../../../../Broadcast/TemplateSelection/byBookmarkedStatus";

export function TemplateTable(props: {
  tabId: string;
  templates: readonly WhatsappTemplateNormalizedType[];
  checkableItems: readonly string[];
  bookmarksPending: string[];
  dispatch: React.Dispatch<
    WhatsappTemplateAction<WhatsappTemplateNormalizedType>
  >;
  toggleBookmark: (sid: string) => void;
}) {
  const {
    tabId,
    templates,
    checkableItems,
    dispatch,
    bookmarksPending,
    toggleBookmark,
  } = props;
  const { t } = useTranslation();
  let bookmarkedCount = templates.filter((t) => t.isBookmarked).length;

  return (
    <Table basic={"very"} className={"app data-grid"}>
      <TableHeader bookmarkedCount={bookmarkedCount} />
      <Table.Body>
        {[...templates].sort(byBookmarkedStatus).map((template) => (
          <TemplateRow
            t={t}
            tabId={tabId}
            toggleBookmark={toggleBookmark}
            dispatch={dispatch}
            checkableItems={checkableItems}
            template={template}
            bookmarkPending={bookmarksPending.includes(template.sid)}
          />
        ))}
      </Table.Body>
    </Table>
  );
}
