import React from "react";

export default function BroadcastIcon() {
  return (
    <svg width={17} height={17} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.925 13.668a.376.376 0 01-.226-.075c-3.523-2.633-7.249-2.766-7.96-2.766-.091 0-.142.002-.147.002a.384.384 0 01-.37-.245c-1.105-2.956-.038-5.471.008-5.577a.377.377 0 01.34-.227c4.497-.064 6.946-1.85 8.123-2.708a.377.377 0 01.598.305l.006 5.797.005 5.117a.377.377 0 01-.377.377zm-8.083-3.594c.904.013 4.324.222 7.705 2.488l-.004-4.387-.005-5.066c-1.372.945-3.776 2.307-7.698 2.42-.206.619-.69 2.459.002 4.545z"
        fill="#fff"
      />
      <path
        d="M2.569 10.83l-.708-.014C.82 10.816 0 9.49 0 7.796c0-1.693.82-3.02 1.868-3.02h.798v.755h-.798c-.527 0-1.114.93-1.114 2.266 0 1.335.587 2.265 1.114 2.265l.715.014-.014.753zM15.323 2.642l-.624.498c.908 1.22 1.466 2.86 1.466 4.661 0 1.803-.558 3.441-1.466 4.661l.624.499c1.02-1.36 1.646-3.17 1.646-5.16 0-1.988-.627-3.799-1.646-5.159zM12.658 5.03c.39.83.613 1.772.613 2.772 0 1-.223 1.942-.613 2.773l.643.514a7.292 7.292 0 00.774-3.287c0-1.191-.282-2.31-.774-3.287l-.643.514zM4.42 14.882c-.603 0-1.094-.49-1.094-1.093v-3.65l2.187.277v3.373c0 .602-.49 1.093-1.094 1.093zm-.34-3.887v2.794a.34.34 0 00.678 0V11.08l-.678-.085z"
        fill="#fff"
      />
    </svg>
  );
}
