import React from "react";

export default function RegistrationIcon() {
  return (
    <svg width={15} height={15} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.103 4.774l-.438-.438a1.197 1.197 0 00-1.69 0L10.93 5.381V2.6A1.728 1.728 0 009.203.873h-3.95c-.008 0-.015.003-.023.004a.394.394 0 00-.093.017.396.396 0 00-.15.08L.681 4.831A.394.394 0 00.6 4.94a.394.394 0 00-.034.083l-.005.016a.397.397 0 00-.011.09V12.4a1.729 1.729 0 001.726 1.726h6.928a1.729 1.729 0 001.727-1.726V9.638l3.173-3.173a1.197 1.197 0 000-1.69zM13.1 4.9l.438.438a.4.4 0 010 .563l-.274.275-1.002-1.002.275-.274a.4.4 0 01.563 0zm-4.563 6.002l-1.342.34.34-1.341L11.7 5.738 12.7 6.739l-4.163 4.163zm-4.252-8.23l.569-.51V3.8a.93.93 0 01-.93.93H1.99l2.297-2.057zm5.847 9.729a.93.93 0 01-.93.93H2.275a.93.93 0 01-.93-.93V5.526h2.58A1.729 1.729 0 005.652 3.8V1.67h3.551a.93.93 0 01.93.93v3.578L6.896 9.415a.397.397 0 00-.104.183l-.534 2.096a.398.398 0 00.484.485l2.098-.531a.398.398 0 00.184-.105l1.109-1.108V12.4z"
        fill="#fff"
      />
    </svg>
  );
}
