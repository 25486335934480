import React from "react";

function ArrowBackIcon() {
  return (
    <svg viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.968 11.928L2.6 7.384 6.97 2.84a.57.57 0 000-.784.52.52 0 00-.754 0L1.469 6.992a.569.569 0 000 .783l4.746 4.936a.52.52 0 00.754 0 .57.57 0 000-.783z"
        fill="var(--color, var(--WHITE))"
      />
    </svg>
  );
}

export default ArrowBackIcon;
