import React from "react";

export default function ReliabilityIcon() {
  return (
    <svg width={15} height={17} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.841 3.434L7.636.136C7.5.051 7.33.068 7.177.136L1.16 3.434a.48.48 0 00-.255.425v4.318a9.121 9.121 0 005.457 8.347l.833.357c.068.034.12.034.187.034a.611.611 0 00.187-.034l.935-.408a9.082 9.082 0 005.576-8.381V3.859a.452.452 0 00-.238-.425zm-.697.714v3.944a8.12 8.12 0 01-4.98 7.497l-.766.323-.646-.272a8.157 8.157 0 01-4.879-7.463V4.148l5.542-3.043 5.73 3.043zM4.542 8.483a.534.534 0 01-.119-.357.467.467 0 01.17-.323.483.483 0 01.68.051L6.6 9.401l3.434-2.771a.448.448 0 01.357-.102.505.505 0 01.323.17.448.448 0 01.102.357.505.505 0 01-.17.323l-3.808 3.077a.475.475 0 01-.306.102.427.427 0 01-.357-.17L4.542 8.483z"
        fill="#fff"
      />
    </svg>
  );
}
